import React, { useState } from 'react';

function DialogueConversation({ scene }) {
  const [userInput, setUserInput] = useState('');
  const [dialogue, setDialogue] = useState([]);

  const handleUserInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleUserInputSubmit = (event) => {
    event.preventDefault();
    addDialogueEntry('user', userInput);
    generateBotResponse(userInput);
    setUserInput('');
  };

  const addDialogueEntry = (sender, message) => {
    const newEntry = { sender, message };
    setDialogue((prevDialogue) => [...prevDialogue, newEntry]);
  };

  const generateBotResponse = (userInput) => {
    // Replace this with your logic to generate a bot response based on user input
    // For now, use a dummy response
    const botResponse = `Dummy bot response for "${userInput}"`;
    addDialogueEntry('bot', botResponse);
  };

  return (
    <div>
      <h3>Dialogue Conversation</h3>
      <div className="dialogue-container">
        {dialogue.map((entry, index) => (
          <div key={index} className={`dialogue-entry ${entry.sender}`}>
            {entry.sender === 'user' ? (
              <p className="user-message">{entry.message}</p>
            ) : (
              <p className="bot-message">{entry.message}</p>
            )}
          </div>
        ))}
      </div>
      <form onSubmit={handleUserInputSubmit}>
        <input type="text" value={userInput} onChange={handleUserInputChange} />
        <button type="submit">Send</button>
      </form>
    </div>
  );
}

export default DialogueConversation;

import React from 'react';
import DialogueConversation from './DialogueConversation';

function SceneDisplay({ selectedScene }) {
  return (
    <div>
      <h3>Scene Display</h3>
      {selectedScene ? (
        <div>
          <h4>Title: {selectedScene.title}</h4>
          <p>Topic: {selectedScene.topic}</p>
          <DialogueConversation scene={selectedScene} />
          {/* Add additional scene information or media display */}
        </div>
      ) : (
        <p>No scene selected</p>
      )}
    </div>
  );
}

export default SceneDisplay;

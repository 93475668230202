import React, { useState } from 'react';

function MovieTopics() {
  // State to store user input and generated responses
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [askedOnce, setAskedOnce] = useState(false); // State to track if user has asked a question

  // Function to handle user input change
  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Replace this with your logic to generate responses
    const dummyResponse = generateDummyResponse(input);
    setOutput(dummyResponse);
    setInput(''); // Clear input field
    if (!askedOnce) {
      setAskedOnce(true); // Set askedOnce to true after the first question
    }
  };

  // Function to generate dummy response
  const generateDummyResponse = (input) => {
    // Replace this with the actual ChatGPT API call or any other logic
    // For now, use the dummy function to generate a response
    return generateDummyText(input);
  };

  // Dummy function to generate dummy text
  function generateDummyText(input) {
    // Replace this with the actual ChatGPT API call or any other logic
    // For now, return a fixed dummy response
    return `Dummy response for "${input}"`;
  }

  return (
    <div>
      <h2>Movie Topics</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" value={input} onChange={handleInputChange} />
        <button type="submit">{askedOnce ? 'Ask Again' : 'Ask'}</button>
      </form>
      <p>{output}</p>
    </div>
  );
}

export default MovieTopics;

import React, { useState } from 'react';
import axios from 'axios';

const API_KEY = '3c20722f4d189a0882441523904083d9';

function DummySceneIntroduction({ onSceneSelect }) {
  const dummyScenes = [
    { id: 1, title: 'Scene 1', topic: 'Topic 1' },
    { id: 2, title: 'Scene 2', topic: 'Topic 2' },
    { id: 3, title: 'Scene 3', topic: 'Topic 3' },
  ];

  const handleSceneSelect = (scene) => {
    onSceneSelect(scene);
  };

  return (
    <div>
      <h3>Select a Scene</h3>
      {dummyScenes.map((scene) => (
        <div
          key={scene.id}
          onClick={() => handleSceneSelect(scene)}
          style={{ cursor: 'pointer', textDecoration: 'underline', marginBottom: '10px' }}
        >
          <p>{`${scene.title}: ${scene.topic}`}</p>
        </div>
      ))}
    </div>
  );
}

function MovieSearch({ onScenesUpdate, onSceneSelect }) {
  const [searchInput, setSearchInput] = useState('');
  const [scenes, setScenes] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [selectedScene, setSelectedScene] = useState(null);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();

    // Perform search for relevant movie scenes based on searchInput
    await fetchScenes();
  };

  const fetchScenes = async () => {
    try {
      const response = await axios.get(
        `https://api.themoviedb.org/3/search/movie?api_key=${API_KEY}&query=${searchInput}`
      );

      const movieData = response.data.results;
      const searchedScenes = movieData.map((movie) => ({
        id: movie.id,
        title: movie.title,
        overview: movie.overview,
        genres: movie.genre_ids,
      }));

      setScenes(searchedScenes);
      onScenesUpdate(searchedScenes); // Pass the searched scenes to the parent component
    } catch (error) {
      console.error('Error fetching movie scenes:', error);
    }
  };

  const handleTitleSelect = (scene) => {
    setSelectedTitle(scene);
    setSelectedScene(null);
  };
  const handleSceneSelect = (scene) => {
    setSelectedScene(scene);
    onSceneSelect(scene); // Pass the selected scene to the parent component
  };

  return (
    <div>
      <h2>Movie Search</h2>
      <form onSubmit={handleSearchSubmit}>
        <input type="text" value={searchInput} onChange={handleSearchInputChange} />
        <button type="submit">Search</button>
      </form>
      {scenes.length > 0 ? (
        <div>
          <h3>Movie Titles</h3>
          {scenes.map((scene) => (
            <div
              key={scene.id}
              onClick={() => handleTitleSelect(scene)}
              style={{ cursor: 'pointer', textDecoration: 'underline', marginBottom: '10px' }}
            >
              <p>{scene.title}</p>
            </div>
          ))}
        </div>
      ) : (
        <p>No scenes found.</p>
      )}
      {selectedTitle && (
        <div>
          <h3>Selected Title</h3>
          <p>{selectedTitle.title}</p>
          <p>{selectedTitle.overview}</p>
          {selectedTitle.genres && <p>Genre: {selectedTitle.genres.join(', ')}</p>}
          <DummySceneIntroduction onSceneSelect={handleSceneSelect} />
        </div>
      )}
      {selectedScene && (
        <div>
          <h3>Selected Scene</h3>
          <p>{selectedScene.title}</p>
          <p>{selectedScene.topic}</p>
        </div>
      )}
    </div>
  );
}

export default MovieSearch;

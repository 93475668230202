// App.js
import React, { useState } from 'react';
import MovieSearch from './components/MovieSearch';
import MovieTopics from './components/MovieTopics';
import SceneDisplay from './components/SceneDisplay';
import PracticeQuestions from './components/PracticeQuestions';
import './App.css';

function App() {
  const [scenes, setScenes] = useState([]); // State to store the scenes
  const [selectedScene, setSelectedScene] = useState(null); // State for the selected scene
  const [selectedTopic, setSelectedTopic] = useState(''); // State for the selected topic

  const handleSceneSelect = (scene) => {
    setSelectedScene(scene);
  };

  const handleTopicSelect = (topic) => {
    setSelectedTopic(topic);
  };

  // Function to update the scenes state with the searched scenes
  const handleScenesUpdate = (searchedScenes) => {
    setScenes(searchedScenes);
  };

  return (
    <div>
      <h1>Movie Chat App</h1>

      <div className="card-container">
        <div className="card">
          <h2>Learning Famous Conversations from a Movie</h2>
          <MovieSearch onSceneSelect={handleSceneSelect} />
        </div>

        <div className="card">
          <h2>Learning about Movie Topics</h2>
          <MovieTopics onSelectTopic={handleTopicSelect} />
        </div>

        <div className="card">
          <h2>Fetching Movie Scenes for Practice</h2>
          {selectedScene ? (
            <SceneDisplay selectedScene={selectedScene} />
          ) : (
            <p>No scene selected</p>
          )}
        </div>

        <div className="card">
          <h2>AI-Generated Practice Questions</h2>
          {selectedScene ? (
            <PracticeQuestions scene={selectedScene} topic={selectedTopic} />
          ) : (
            <p>No scene selected</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';

function PracticeQuestions({ scene }) {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [completed, setCompleted] = useState(false);

  // Simulated API call to fetch questions related to the scene
  const fetchQuestions = async () => {
    // Replace this with your actual API call to fetch questions
    // You can use the scene information to generate relevant questions
    // For demonstration purposes, we'll use dummy data

    // Dummy questions
    const dummyQuestions = [
      { id: 1, question: 'What is the main conflict in the scene?' },
      { id: 2, question: 'Describe the emotions of the main character in this scene.' },
      { id: 3, question: 'What is the significance of the setting in this scene?' },
    ];

    setQuestions(dummyQuestions);
  };

  // Handle user's answer to a question
  const handleAnswer = (event) => {
    const { name, value } = event.target;
    setUserAnswers((prevAnswers) => ({ ...prevAnswers, [name]: value }));
  };

  // Move to the next question
  const nextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      setCompleted(true);
    }
  };

  // Reset the chatbot
  const resetChatbot = () => {
    setCurrentQuestionIndex(0);
    setUserAnswers({});
    setCompleted(false);
  };

  // Fetch questions when the scene changes
  useEffect(() => {
    fetchQuestions();
  }, [scene]);

  if (completed) {
    return (
      <div>
        <h3>Practice Questions Completed</h3>
        <button onClick={resetChatbot}>Start Over</button>
      </div>
    );
  }

  if (questions.length === 0) {
    return <p>Loading questions...</p>;
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div>
      <h3>Practice Questions</h3>
      <p>Question {currentQuestionIndex + 1}:</p>
      <p>{currentQuestion.question}</p>
      <input
        type="text"
        name={`answer${currentQuestion.id}`}
        value={userAnswers[`answer${currentQuestion.id}`] || ''}
        onChange={handleAnswer}
      />
      <button onClick={nextQuestion}>Next</button>
    </div>
  );
}

export default PracticeQuestions;
